<template>
    <div>
        <b-col cols="12">
            <b-table
                striped
                hover
                responsive
                sort-icon-left
                :busy="isBusy"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
            >


                <template v-slot:head()="data">
                    {{ $t(`${data.label}`) }}
                </template>


                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-1">{{$t('loading')}}</strong>
                    </div>
                </template>

                <template #cell(recipient_fullName)="data">
                    {{ fullName(data.item) }}
                </template>


                <template #cell(address)="data">
                    {{ fullAddress(data.item) }}
                </template>


                <template #cell(issuer)="data">
                    {{ data.item.issuer_id }}
                </template>

                <!--                <template #cell(role)="data">-->
                <!--                    {{ data.item.role.name }}-->
                <!--                </template>-->


            </b-table>
        </b-col>

        <!--  PAGINATION  -->
        <b-col
            cols="12"
        >
            <b-pagination
                v-if="showPagination"
                v-model="pagination.current"
                :total-rows="pagination.totalItems"
                :per-page="pagination.perPage"
                align="center"
                size="sm"
                class="mt-0"
            ></b-pagination>
        </b-col>
    </div>
</template>

<script>
import {
    BTable,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BFormCheckbox,
    BSpinner,
    BCard,

} from 'bootstrap-vue'
import BaseDatePicker from "@/views/ui/BaseDatePicker";
import ModalButton from "@/views/ui/modals/ModalButton";
import ToastNotification from "@core/components/toastification/ToastNotification";
import InfinityScrollSelect from "@/views/ui/infinity-scroll/InfinityScrollSelect";
import DatePicker from "vue2-datepicker";
import FileUploader from "@/views/ui/FileUploader";

export default {
    name: "Success",
    components: {
        BaseDatePicker,
        BTable,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BFormInput,
        BButton,
        BFormCheckbox,
        BSpinner,
        BCard,
        ModalButton,
        ToastNotification,
        InfinityScrollSelect,
        DatePicker,
        FileUploader
    },
    props: {
        success: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            fields: [
                {
                    key: 'id',
                    label: 'ID',
                },
                {
                    key: 'type',
                    label: 'type',
                },
                {
                    key: 'recipient_fullName',
                    label: 'recipient_fullName',
                },
                {
                    key: 'recipient_primary_account_number',
                    label: 'recipient_primary_account_number',
                },
                {
                    key: 'consent_date_time',
                    label: 'consent_date_time',
                },
                {
                    key: 'expiry_date',
                    label: 'expiry_date',
                },
                {
                    key: 'contact_phone_number',
                    label: 'phone',
                },
                {
                    key: 'contact_email',
                    label: 'email',
                },
                {
                    key: 'postal_code',
                    label: 'Postal kod',
                },
                {
                    key: 'address',
                    label: 'address',
                },
                {
                    key: 'issuer',
                    label: 'issuer',
                },
            ],
            loading: false,
            isBusy: false,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },
    computed: {
        items() {
            return this.success.items || []
        },
        pagination() {
            return this.success.pagination || {}
        },
        hasItems() {
            return this.items.length > 0
        },

        showPagination() {
            return this.hasItems && !this.isBusy
        },

    },
    methods: {
        fullName(item) {
            if (item && item.recipient_first_name && item.recipient_last_name) {
                return item.recipient_first_name + ' ' + item.recipient_last_name
            }
        },
        fullAddress(item) {
            // const country = await this.getCountryName(item.country_id)
            if (item && item.address1 && item.address2) {
                return item.country_id + ', ' + item.address1 + ', ' + item.address2
            }
        },
    }

}
</script>

<style scoped>

</style>